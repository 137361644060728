import { EditorReadyFn } from '@wix/yoshi-flow-editor';
import { installAppComponents } from './appSetup';

export default {
  editorReady: async (editorSDK, appDefinitionId, platformOptions, flowAPI) => {
    const { reportError, fedops } = flowAPI;

    fedops.appLoadStarted();
    try {
      if (platformOptions.firstInstall) {
        fedops.interactionStarted('install');
        // Place for your app initialization here. (Add pages to editor platform, init configs, etc);
        console.log('App was installed ✅');
        fedops.interactionEnded('install');
        installAppComponents(editorSDK);
      }
    } catch (e: any) {
      reportError(e);
    }

    fedops.appLoaded();
  },
  handleAction: () => {},
  getAppManifest: () => {
    return {
      pages: {
        pageActions: {
          default: [],
          collectionPage: [],
        },
        pageSettings: {
          default: [],
          collectionPage: [],
        },
        applicationSettings: {
          default: {
            displayName: 'Collections Pages',
            helpId: '',
          },
        },
        applicationActions: {
          default: [],
        },
      },
    };
  },
} as {
  editorReady: EditorReadyFn;
  handleAction: () => {};
  getAppManifest: any;
};

// const editorApp = withMembersArea(AlbumsCollectionsEditorApp);

// export const editorReady = editorApp.editorReady;
// export const handleAction = editorApp.handleAction;
// export const onEvent = editorApp.onEvent;
// export const getAppManifest = editorApp.getAppManifest;
